var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.resourceData)?_c('section',[_c('b-row',{staticClass:"content-header"},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"breadcrumb-wrapper"},[_c('b-breadcrumb',[_c('b-breadcrumb-item',{attrs:{"to":{ name: 'dashboard' }}},[_c('feather-icon',{staticClass:"align-text-top",attrs:{"icon":"HomeIcon","size":"16"}}),_vm._v(" Dashboard ")],1),_c('b-breadcrumb-item',{attrs:{"to":{ name: 'view-printer', params: { id: _vm.resourceId } }}},[_vm._v(" "+_vm._s(_vm.resourceName)+" ")]),_c('b-breadcrumb-item',{attrs:{"active":true}},[_vm._v(" Edit ")])],1)],1)]),_c('b-col',{staticClass:"mb-2 text-right",attrs:{"md":"12"}},[_c('b-button',{attrs:{"variant":"primary","to":{ name: 'view-printer', params: { id: _vm.resourceId } }}},[_c('feather-icon',{staticClass:"align-text-top",attrs:{"icon":"EyeIcon","size":"16"}}),_vm._v(" View "+_vm._s(_vm.resourceName)+" ")],1)],1)],1),_c('b-card',{staticClass:"p-1"},[_c('b-card-title',{staticClass:"pl-1 border-left-primary border-left-3"},[_vm._v(" Edit "+_vm._s(_vm.resourceName)+" ")]),_c('validation-observer',{ref:"resourceForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.updatePrinter.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Company Name","label-for":"company"}},[_c('validation-provider',{attrs:{"name":"Company Name","rules":"required","vid":"company"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company","name":"company","state":errors.length > 0 ? false:null,"placeholder":"Company Name"},model:{value:(_vm.resourceData.company),callback:function ($$v) {_vm.$set(_vm.resourceData, "company", $$v)},expression:"resourceData.company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Full Name","label-for":"full_name"}},[_c('validation-provider',{attrs:{"name":"Full Name","rules":"required","vid":"full_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"full_name","name":"full_name","state":errors.length > 0 ? false:null,"placeholder":"Full Name"},model:{value:(_vm.resourceData.full_name),callback:function ($$v) {_vm.$set(_vm.resourceData, "full_name", $$v)},expression:"resourceData.full_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","name":"email","state":errors.length > 0 ? false:null,"placeholder":"Email"},model:{value:(_vm.resourceData.email),callback:function ($$v) {_vm.$set(_vm.resourceData, "email", $$v)},expression:"resourceData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":invalid || !dirty}},[_vm._v(" Update ")])],1)],1)],1)]}}],null,false,1337297797)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }